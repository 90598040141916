<template>
  <div class="job_detail" v-loading="loading" element-loading-text="拼命加载中">
    <div class="header">
      <div class="header_content">
        <div class="resume" v-if="companyLogin != 2">
          <a
            href="javascript:void(0);"
            @click="goRouter('/center/resume', 'login')"
          >
            <img src="@/static/job/detail_banner.png" alt="完善简历" />
          </a>
        </div>
        <div
          class="header_bottom"
          :style="{ paddingTop: companyLogin != 2 ? '0px' : '20px' }"
        >
          <div class="time">
            <img
              src="@/static/job/time.png"
              alt="发布时间"
              class="img inline_block"
            />
            {{ detail.updateTime | currentYear(detail.updateTime) }}
          </div>
          <br />

          <div class="share relative">
            <span>分享到 <i class="el-icon-arrow-down"></i></span>
            <Share
              :url="shareUrl"
              :title="detail.jobName"
              :desc="'由' + detail.companyInfo.companyName + '发布'"
              v-if="detail.companyInfo"
            ></Share>
          </div>
          <div class="stopjob" v-if="stopsign">
            <img src="../../static/login/lost.png" alt="" />
          </div>
          <h1>{{ detail.jobName }}</h1>
          <p class="label" v-if="detail.isPressing == '1'">急聘</p>
          <span class="money">{{ detail.jobSalary }}</span>

          <p v-if="detail.distype">
            <span>{{ detail.cityid }}{{ detail.threeCityid }}<i></i></span>
            <span>{{ detail.edu || "学历不限" }}<i></i></span>
            <span>{{ detail.exp || "经验不限" }}<i></i></span>
            <!--            <span v-for="(n,i) in detail.distype" :key="i" :class="'distype'+i">{{ n.disType }}/{{ n.disTypeLevel }}<em>，</em></span>-->
            <span class="tip" v-if="detail.distype.length > 0"
              >（此职位适合残疾类别
              <span v-for="(n, i) in detail.distype" :key="i"
                >{{ n.disType }}/{{ n.disTypeLevel }}<em>，</em></span
              >）
            </span>
            <span class="tip" v-else> （此职位残疾类别适合所有残疾人） </span>
          </p>
          <div class="down flex" v-if="detail.jobLabels">
            <div class="welfare" v-if="detail.jobLabels.length > 0">
              <span v-for="(n, i) in detail.jobLabels" :key="i">{{
                n.labelName
              }}</span>
            </div>
            <div class="welfare" v-else>
              <span>暂无标签</span>
            </div>
            <div class="buttons" v-if="companyLogin != 2 && !stopsign">
              <el-button @click="collection()">
                <img
                  :src="
                    detail.collect
                      ? require('@/static/public/collected.png')
                      : require('@/static/public/collect.png')
                  "
                  alt=""
                  aria-hidden="true"
                  class="img inline_block"
                />
                {{ detail.collect ? "已收藏" : "收藏" }}
              </el-button>
              <el-button type="info" :disabled="loadings" v-if="detail.apply"
                >已投递
              </el-button>
              <el-button
                type="primary"
                :disabled="loadings"
                element-loading-text="投递载入中"
                v-loading="loadings"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 146, 75, 0.8)"
                element-loading-color="#ffffff"
                @click="delivery(detail.id)"
                v-else
                >投递简历
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content flex">
      <div class="content_left">
        <div class="content_top">
          <!-- <h2>以下为当前职位描述</h2> -->
          <h4>职位描述：</h4>
          <div
            class="text"
            v-html="detail.jobClobOutDTO.jobDescript"
            v-if="detail.jobClobOutDTO"
          ></div>
          <div class="text" v-else>暂无</div>
          <h4>任职要求：</h4>
          <div
            class="text"
            v-html="detail.jobClobOutDTO.jobRequirement"
            v-if="detail.jobClobOutDTO"
          ></div>
          <div class="text" v-else>暂无</div>
          <h2 class="address_name">办公地址</h2>
          <div class="address relative">
            <i class="el-icon-location-outline"></i>
            <span
              >{{ detail.provinceid }}-{{ detail.cityid }}-{{
                detail.threeCityid
              }}</span
            >
            <!-- -{{ detail.address }} -->
<!--            <p @click="maps = !maps">-->
<!--              查看地图-->
<!--              <i-->
<!--                class=""-->
<!--                title="展开/收起"-->
<!--                :class="maps ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"-->
<!--              ></i>-->
<!--            </p>-->
          </div>
<!--          <div class="map" v-show="maps">-->
<!--            <div id="mapContainer"></div>-->
<!--          </div>-->
        </div>
        <div class="tip" v-if="companyLogin != 2 && !stopsign">
          <h3 class="flex"><img src="~/static/job/anqan.png" alt="">平台安全提示</h3>
          <p>
            本平台禁止用人单位作出任何损害残疾人合法权益的违法违规行为；包括但不限于扣押求职者体检费、材料费、保证金、买卖或租借银行卡、电话卡、网络支付账号、要求添加微信/QQ进行转账汇款、刷单返利、虚假投资理财、虚假网络贷款、冒充电商物流客服、冒充公检法、向求职者集资、让求职者入股等；
            如您遇到此类情况请立即点击下方按钮进行举报。
            <a style="color:#28A46D;text-decoration:underline" target="_blank"
               href="https://www.cdpee.org.cn/training/publicDetail?id=f4f7f03ad5e5409e89e59890fa25cef5">了解更多职场安全防范知识</a>
          </p>
          <div class="btn" @click="openReport">举报该职位</div>
          <img class="bg" src="~/static/job/anquan.png" alt="">
        </div>
        <div class="title relative marT20">
          <h2>相似职位</h2>
          <div class="reset cursorP color00" @click="likeJob">
            换一换 <i class="el-icon-refresh" aria-hidden="true"></i>
          </div>
        </div>
        <ul
          class="job_list"
          v-loading="loading1"
          element-loading-text="拼命加载中"
          v-if="this.jobList.length > 0"
        >
          <li class="box marB10" v-for="(item, index) in jobList" :key="index">
            <div class="top flex">
              <div class="left" @click="goRouters(urlS+'/job/jobDetail/', item.id)">
                <div class="flex">
                  <h3 class="line_clamp1">
                    <router-link
                      :to="urlS+'/job/jobDetail/?id=' + item.id"
                      target="_blank"
                      :title="item.jobName"
                      >{{ item.jobName }}
                    </router-link>
                  </h3>
                  <small
                    >「{{
                      item.updateTime | currentYear(item.updateTime)
                    }}发布」</small
                  >
                </div>

                <p>
                  <span class="money" v-if="item.jobSalary">{{
                    item.jobSalary
                  }}</span>
                  <span class="money" v-else>面议</span>
                  <span v-if="item.threeCityid">
                    {{ item.threeCityid }}
                    <i></i>
                  </span>
                  <span v-else>
                    暂无地址
                    <i></i>
                  </span>
                  <span v-if="item.edu">
                    {{ item.edu }}
                    <i></i>
                  </span>
                  <span v-else>
                    学历不限
                    <i></i>
                  </span>
                  <span v-if="item.exp">
                    {{ item.exp }}
                    <i></i>
                  </span>
                  <span v-if="item.distype.length > 1"> 多种残疾/不限 </span>
                  <span v-else
                    ><span v-for="(k, s) in item.distype" :key="s">
                      {{ k.disType }}
                      <em v-if="k.disTypeLevel">/</em>
                      {{ k.disTypeLevel }}
                    </span></span
                  >
                </p>
              </div>
              <div
                class="right"
                v-if="item.companyInfo"
                @click="goRouter(urlS+'/job/businessDetails', item.companyId)"
              >
                <router-link
                  :to="urlS+'/job/businessDetails/?companyId=' + item.companyId"
                  target="_blank"
                  ><h3 class="line_clamp1">
                    {{ item.companyInfo.companyName }}
                  </h3></router-link
                >

                <div class="info">
                  <p>
                    <span v-if="item.companyInfo.hyTop">
                      {{ item.companyInfo.hyTop }}
                      <i></i>
                    </span>
                    <span v-else>
                      暂无行业类别
                      <i></i>
                    </span>
                    <span v-if="item.companyInfo.pr">
                      {{ item.companyInfo.pr }}
                      <i></i>
                    </span>
                    <span v-else>
                      暂无公司性质
                      <i></i>
                    </span>
                    <span v-if="item.companyInfo.mun">
                      {{ item.companyInfo.mun }}
                      <i></i>
                    </span>
                    <span v-else>
                      暂无企业人数
                      <i></i>
                    </span>
                  </p>
                </div>
              </div>
              <img
                :src="item.companyInfo.logo"
                class="logo img flexs err_logo"
                :alt="item.companyInfo.logo ? item.companyInfo.companyName : ''"
                :class="item.companyInfo.logo ? '' : 'err_logo'"
              />
            </div>
            <div class="bottom flex">
              <div class="left" v-if="item.jobLabels.length > 0">
                <span v-for="(n, i) in item.jobLabels" :key="i">{{
                  n.labelName
                }}</span>
              </div>
              <div class="left" v-else>
                <span>暂无福利标签</span>
              </div>
              <!-- <div class="right">
                <span>简历处理速度</span>
                <div class="schedule">
                  <div :style="{'width':item.companyInfo.vate+'%'}"></div>
                </div>
                <span>{{ item.companyInfo.vate }}%</span>
              </div>-->
            </div>
          </li>
        </ul>
        <status type="result" v-else des="暂无相似职位"></status>
      </div>
      <div class="content_right" v-if="detail.companyInfo">
        <div class="company">
          <img
            :src="detail.companyInfo.logo"
            class="logo"
            :class="detail.companyInfo.logo ? '' : 'err_logo'"
            :alt="detail.companyInfo.logo ? detail.companyInfo.companyName : ''"
            :title="detail.companyInfo.companyName"
          />
          <h2>{{ detail.companyInfo.companyName }}</h2>
          <span class="attest">机构认证</span>
          <el-button
            class="url"
            @click="goRouter(urlS+'/job/businessDetails', detail.companyId)"
          >
            <router-link :to="urlS+'/job/businessDetails'" target="_blank"
              >查看主页
            </router-link>
          </el-button>
        </div>
        <div class="info">
          <h2>基本信息</h2>
          <ul>
            <li v-if="detail.companyInfo.hyTop">
              <i aria-hidden="true" class="el-icon-menu"></i
              >{{ detail.companyInfo.hyTop }}
            </li>
            <li v-if="detail.companyInfo.mun">
              <i aria-hidden="true" class="el-icon-s-custom"></i
              >{{ detail.companyInfo.mun }}
            </li>
            <li v-if="detail.companyInfo.pr">
              <i aria-hidden="true" class="el-icon-s-data"></i
              >{{ detail.companyInfo.pr }}
            </li>
            <li v-if="detail.companyInfo.website">
              <i aria-hidden="true" class="el-icon-s-platform"></i>
              <a
                :href="detail.companyInfo.website"
                target="_blank"
                :title="detail.companyInfo.website"
              >
                {{ detail.companyInfo.website }}
              </a>
            </li>
          </ul>
        </div>
        <div class="list">
          <h2>其他职位</h2>
          <ul v-if="companyList">
            <li v-for="(item, index) in companyList" :key="index">
              <div class="name">
                <router-link
                  :to="urlS+'/job/jobDetail/?id=' + item.id"
                  target="_blank"
                  :title="item.jobName"
                  >{{ item.jobName }}
                </router-link>
              </div>
              <p class="line_clamp1">
                <span class="money">{{ item.jobSalary }}</span>
                <span>{{ item.cityid }}-{{ item.threeCityid }}<i></i></span>
                <span>{{ item.edu }}<i></i></span>
                <span>{{ item.exp }}</span>
              </p>
              <div class="time">{{ item.updateTime }}</div>
            </li>
          </ul>
          <div class="more" v-if="companyList">
            <router-link
              :to="
                urlS+'/job/businessDetails?active=1&companyId=' +
                detail.companyInfo.companyId
              "
              target="_blank"
            >
              查看全部 <i aria-hidden="true" class="el-icon-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <login-prompt
      :goShow="isHide"
      @close="handleClose"
      title="职位申请"
      :text="title"
    ></login-prompt>
    <!--举报-->
    <el-dialog
      title="举报职位"
      :visible.sync="dialogVisible"
      width="590px"
      :before-close="handleClose"
    >
      <div class="world el-dialog_content">
        <div class="tip">
          <h4>
            <small>{{ detail.jobName }}</small>
          </h4>
        </div>
        <el-form
          ref="form"
          :model="goForm"
          :rules="err"
          label-width="80px"
          class="form"
        >
          <el-form-item label="举报原因" prop="descripce">
            <edit
              placeholder="请输入举报原因"
              class="w430"
              @html="hanContent"
            ></edit>
          </el-form-item>
          <el-form-item label="联系人" prop="name">
            <el-input
              placeholder="请填写联系人"
              v-model="goForm.name"
              class="w430"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="tel">
            <el-input
              placeholder="请填写联系电话"
              v-model="goForm.tel"
              class="w430"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系邮箱" prop="email">
            <el-input
              placeholder="请填写联系邮箱"
              v-model="goForm.email"
              class="w430"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" plain
          >取 消
        </el-button>
        <el-button type="primary" @click="report()">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="职位收藏"
      :visible.sync="dialogVisibles"
      width="480px"
      :before-close="handleClose"
    >
      <div class="el-dialog_contents">
        <div class="title">
          <img src="@/static/public/success.png" alt="" class="img" />
          职位收藏成功！
        </div>
        <p>
          您可以在
          <router-link to="/center/collect/" target="_blank"
            >职位收藏夹
          </router-link>
          中查看
        </p>
      </div>
      <div slot="footer" class="dialog-footer text_c">
        <el-button type="primary" @click="dialogVisibles = false"
          >知道了
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="岗位申请"
      :visible.sync="dialogVisiblejob"
      width="480px"
      :before-close="handleClose"
    >
      <div class="el-dialog_contents">
        <div class="title">
          <img src="@/static/public/success.png" alt="" class="img" />
          投递成功！
        </div>
        <p class="ikone">15天内不能重复申请</p>
      </div>
      <div slot="footer" class="dialog-footer text_c">
        <el-button type="primary" @click="dialogVisiblejob = false"
          >知道了
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="投递简历"
      :visible.sync="dialogVisibleApply"
      width="480px"
      :before-close="handleClose"
    >
      <div class="el-dialog_contents">
        <div class="title">
          <img src="@/static/public/applys.png" alt="" class="img" />
          信息不匹配
        </div>
        <p>您的残疾类别与该职位要求不匹配，确定要投递吗？</p>
      </div>
      <div slot="footer" class="dialog-footer text_c">
        <el-button type="primary" :disabled="detail.apply" @click="deliverys"
          >确定</el-button
        >
        <el-button type="primary" @click="dialogVisibleApply = false" plain
          >再想想
        </el-button>
      </div>
    </el-dialog>
    <authentication :show="is_disabled" @close="handleClose()"></authentication>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
import edit from "@/components/public/editor";
import Share from "@/components/public/Collect";
import authentication from "@/components/public/authentication";

export default {
  name: "jobDetail",
  metaInfo() {
    return {
      meta: this.metaData,
    };
  },
  components: {
    loginPrompt,
    edit,
    Share,
    authentication,
  },
  data() {
    return {
      location: "",
      is_disabled: false,
      stopsign: false,
      metaData: [],
      detail: [],
      jobList: [],
      companyList: [],
      form: {
        id: "",
        companyId: "",
      },
      collect: false,
      maps: false,
      zoom: 11, //地图展示级别
      postionMap: {
        //经纬度
        lat: "",
        lng: "",
      },
      goForm: {
        jobId: "",
        comId: "",
        descripce: "",
        name: "",
        tel: "",
        email: "",
      },
      err: {
        descripce: [
          { required: true, message: "原因不能为空", trigger: "blur" },
        ],
        name: [
          {
            required: true,
            message: "联系人的名字不能为空",
            trigger: "change",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入您有效的电子邮箱",
            trigger: "blur",
          },
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: "电子邮箱格式不对",
            trigger: "blur",
          },
        ],
      },
      mapInt: null,
      isHide: false,
      disabled: false,
      dialogVisible: false,
      dialogVisibles: false,
      dialogVisibleApply: false,
      dialogVisiblejob: false,
      loading: false,
      loading1: false,
      loadings: false,
      isLogin: "",
      companyLogin: "",
      title: "",
      shareUrl: "",
      ts: "",
      urlS:'',
    };
  },
  watch: {
    $route() {
      this.info();
    },
    deep: true,
  },
  filters: {
    // 获取当前年
    currentYear(time) {
      let date = new Date();
      let years = date.getFullYear();
      let createTime = "";
      if (time) {
        if (time.slice(0, 4) == years) {
          createTime = time.split(years + "年").join("");
        } else {
          createTime = time;
        }
      }
      return createTime;
    },
  },
  methods: {
    async info() {
      this.loading = true;
      await this.$axios
        .get("/api/app-jycy-job/getJobDetailByid/", {
          id: this.$route.query.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.detail = res.data.data.jobOutDTO;
            const status = res.data.data.status;
            // 0 职位已失效 1职位尚未生效 2职位已经过期
            if ([0, 1, 2].includes(status)) {
              this.stopsign = true;
            }
            this.getDescription();
            let address =
              this.detail.provinceid +
              this.detail.cityid +
              this.detail.threeCityid;
            this.shareUrl = window.location.href;
            this.isLogin =
              this.$store.state.userType ||
              JSON.parse(localStorage.getItem("userType"));
            if (this.isLogin) {
              this.companyLogin = this.isLogin.userType;
            }
            this.likeJob();
            this.companyJob();
            this.$nextTick(() => {
              this.getAddress(this.detail)
            })
            if (this.detail.companyInfo.website) {
              this.detail.companyInfo.website =
                this.detail.companyInfo.website.substr(0, 7).toLowerCase() ==
                "http://"
                  ? this.detail.companyInfo.website
                  : "http://" + this.detail.companyInfo.website;
            }
          } else {
            this.stopsign = true;
            // this.$message.error(res.data.msg);
          }
        });
    },
    //相似职位
    likeJob() {
      this.loading1 = true;
      this.$axios
        .get("/api/app-jycy-job/getLikeJobList", {
          id: this.detail.id,
          pageSize: 5,
        })
        .then((res) => {
          this.loading1 = false;
          if (res.data.success) {
            this.jobList = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading1 = false;
        });
    },
    //公司在招职位
    companyJob() {
      this.$axios
        .get("/api/app-jycy-job/getJobListByMemberId", {
          companyId: this.detail.companyId,
          pageSize: 5,
        })
        .then((res) => {
          if (res.data.success) {
            this.companyList = res.data.data.records;
          }
        })
        .catch((err) => {});
    },
    //收藏
    collection() {
      if (this.isLogin) {
        if (this.isLogin.userType == "1") {
          this.$axios
            .post("/api/app-jycy-job/addOrRemoveJobCollect", {
              jobId: this.detail.id,
            })
            .then((res) => {
              if (res.data.success) {
                console.log(this.detail.collect);
                this.detail.collect = !this.detail.collect;
                if (this.detail.collect) {
                  this.dialogVisibles = true;
                } else {
                  this.$message.success("取消收藏");
                }
              }
            });
        } else if (this.isLogin.userType == "2") {
          this.$message.error("您不是残疾人，不能收藏职位");
        } else {
          this.title = "登录之后才能收藏职位!";
          this.isHide = true;
        }
      } else {
        this.isHide = true;
        this.title = "登录之后才能收藏职位!";
      }
    },
    //打开举报
    openReport() {
      if (this.isLogin) {
        if (this.isLogin.userType == "1") {
          this.dialogVisible = true;
        } else if (this.isLogin.userType == "2") {
          this.$message.error("您不是残疾人，不能举报职位");
        } else {
          this.title = "登录之后才能举报职位!";
          this.isHide = true;
        }
      } else {
        this.isHide = true;
        this.title = "登录之后才能举报职位!";
      }
    },
    //地图
    handler({ BMap, map }) {
      this.zoom = 11;
    },
    //获取富文本的内容
    hanContent(val) {
      this.goForm.descripce = val;
    },
    //投递
    deliverys() {
      this.detail.apply = true;
      if (this.isLogin.userType == "1") {
        this.$axios
          .post("/api/app-jycy-jobapply/disAbleAddJobApply", {
            jobId: this.detail.id,
            companyId: this.detail.companyId,
          })
          .then((res) => {
            if (res.data.success) {
              this.detail.apply = true;
              this.$message.success(res.data.msg);
              this.dialogVisiblejob = true;
              setTimeout(() => {
                this.handleClose();
              }, 1500);
            } else {
              this.detail.apply = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.detail.apply = false;
          });
      } else {
        // this.$message.error("您不是残疾人，不能投递职位");
      }
    },
    delivery(id) {
      let jobName= this.detail.jobName;
      if (jobName.indexOf('财务') > -1 && jobName.indexOf('测试') == -1) {
        this.$alert(
            '本平台禁止用人单位作出任何损害残疾人合法权益的违法违规行为，如有企业以电话、微信/QQ等方式联系您，有如下行为，包括但不限于扣押求职者体检费、材料费、保证金、买卖或租借银行卡、电话卡、网络支付账号、进行转账汇款、刷单返利、虚假投资理财、虚假网络贷款、冒充电商物流客服、冒充公检法、向您集资、让您入股等，均为违规行为；如您遇到此类情况请立即点击本条职位下方按钮进行举报。',
            '温馨提示，请您认真查看：',
            {
              confirmButtonText: '知道了',
              cancelButtonText: '取消',
              showCancelButton: true,
              callback: action => {
                if (action == 'confirm') {
                  // --------------------
                  applyFn.call(this);
                  // ---------------------------
                }
              }
            }
        );
      } else {
        applyFn.call(this);
      }
      return;
      function applyFn() {
        if (this.isLogin) {
          this.loadings = true;
          if (this.isLogin.userType == '1') {
            //完善简历
            this.$api.loginIfFullApi().then(ref => {
              if (ref.data.isComplete == 0) {
                this.$confirm('简历尚未完善，是否完善', '完善简历', {
                  confirmButtonText: '去完善',
                  type: 'warning'
                })
                    .then(() => {
                      this.loadings = false;
                      this.$router.push('/userLogin/userInfo');
                    })
                    .catch(() => {
                      this.loadings = false;
                      this.$message({
                        type: 'info',
                        message: '已取消'
                      });
                    });
              } else {
                this.getFileResume = ref.data.fileResume || 0;
                if (this.getFileResume == '1') {
                  this.fileResume = true;
                }
                this.$api
                    .disAbleJobRemind({
                      jobId: id
                    })
                    .then(res => {
                      if (res.data) {
                        this.detail.apply = false;
                        this.loadings = false;

                        if (res.data.code && res.data.code == 500) {
                          console.log(res.data, res.data.code, '**');
                          this.$message.error(res.msg || '系统错误');
                          return;
                        }
                        this.ts = res.data.ts;
                        if (this.ts == 1) {
                          this.dialogVisibleApply = true;
                          this.dialogVisibleApplyText =
                              '您的残疾类别与该职位要求不匹配，确定要投递吗？';
                        } else if (this.ts == 2) {
                          this.dialogVisibleApply = true;
                          this.dialogVisibleApplyText =
                              '您所在地区与该职位要求不匹配，确定要投递吗？';
                        } else if (this.ts == 3) {
                          this.dialogVisibleApply = true;
                          this.dialogVisibleApplyText = '您的学历与该职位要求不匹配，确定要投递吗？';
                        } else {
                          this.$axios
                              .post('/api/app-jycy-jobapply/disAbleAddJobApply', {
                                jobId: id,
                                companyId: this.detail.companyId,
                                fileResume: this.fileResume ? '1' : '0',
                                jobApplySource: this.$route.query.jobApplySource || 4
                              })
                              .then(res => {
                                if (res.data.success) {
                                  this.detail.apply = true;
                                  this.dialogVisibleApplyText = '投递简历';
                                  this.dialogVisibleApply = false;
                                  // this.dialogVisiblejob = true
                                  // setTimeout(() => {
                                  //   this.handleClose()
                                  // }, 1500)
                                  if (this.detail.jobSourceSite == 2 && this.detail.jobSource == 2) {
                                    this.resumeToXy();
                                  } else {
                                    this.dialogVisiblejob = true;
                                    this.$message.success(res.data.msg);
                                    setTimeout(() => {
                                      this.handleClose();
                                    }, 1500);
                                  }
                                } else {
                                  if (res.data.code == 8230) {
                                    this.$confirm(res.data.msg || '请完善简历后投递', '提示', {
                                      confirmButtonText: '去完善',
                                      cancelButtonText: '取消',
                                      type: 'warning'
                                    }).then(() => {
                                      this.dialogVisibleApply = false;
                                      this.detail.apply = false;
                                      this.goRouter('/center/resume', 'login');
                                    });
                                    return;
                                  } else {
                                    this.detail.apply = false;
                                    res.data.msg && this.$message.error(res.data.msg || '');
                                  }
                                }
                              })
                              .catch(err => {
                                this.detail.apply = false;
                              });
                        }
                      } else {
                        this.loadings = false;
                      }
                    })
                    .catch(err => {});
              }
            });
          } else {
            // this.$message.error('您不是残疾人，不能投递职位');
            // this.is_disabled = true;
            this.loadings = false;
          }
        } else {
          this.isHide = true;
          this.title = '登录之后才可以投递职位!';
        }
      }
    },
    handleClose() {
      this.isHide = false;
      this.dialogVisible = false;
      this.dialogVisibles = false;
      this.dialogVisibleApply = false;
      this.dialogVisiblejob = false;
      this.is_disabled = false;
      this.loadings = false;
    },
    //举报
    report() {
      this.goForm.jobId = this.detail.id;
      this.goForm.comId = this.detail.companyId;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/api/app-jycy-job/addJobReport", this.goForm)
            .then((res) => {
              if (res.data.success) {
                this.$message.success(res.data.msg);
                setTimeout(() => {
                  this.handleClose();
                }, 1500);
              }
            })
            .catch((err) => {});
        } else {
          this.loading = false;
          this.$message.error("你有信息未填写，请检查后再提交！");
        }
      });
    },
    //去其他页面
    goRouter(url, id) {
      if (id == "login") {
        if (this.isLogin) {
          if (this.isLogin.userType == "1") {
            const { href } = this.$router.resolve({
              path: url,
              query: {
                id: this.isLogin.id,
                userType: 1,
              },
            });
            window.open(href, "_blank");
          } else {
            this.$message.error("您不是残疾人，不用完善简历");
          }
        } else {
          this.title = "登录之后才能查看简历!";
          this.isHide = true;
        }
      } else {
        const { href } = this.$router.resolve({
          path: url,
          query: {
            companyId: id,
          },
        });
        window.open(href, "_blank");
      }
    },
    goRouters(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
          userType: 1,
        },
      });
      window.open(href, "_blank");
    },
    //根据地址获取经纬度
    getAddress(detail) {
      let { provinceid, cityid, threeCityid, address } = detail
      if (!provinceid) return
      let _address =
          (provinceid || '') +
          (cityid || '') +
          (threeCityid || '') +
          (address || '')
      console.log('地图', _address)
      if (this.mapInt) return
      this.mapInt = new BMapGL.Map('mapContainer')
      let map = this.mapInt
      // map.centerAndZoom(new BMapGL.Point(116.331398,39.897445), 10); // 初始化地图，设置中心点和地图级别
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
      //创建地址解析器实例
      var myGeo = new BMapGL.Geocoder()
      // 将地址解析结果显示在地图上，并调整地图视野
      myGeo.getPoint(
          _address,
          function (point) {
            console.log(point, 'point')
            if (point) {
              setTimeout(() => {
                map.centerAndZoom(point, 16) // 设置中心点和地图级别
                map.addOverlay(new BMapGL.Marker(point)) // 添加标记点
                // map.setCenter(point)
              }, 0)
            } else {
              console.error('您选择的地址没有解析到结果！')
              document.querySelector('#mapContainer').style.display = 'none'
              document.querySelector('.toggle-up-dwon').style.display = 'none'
            }
          },
          '全国'
      )
    },
    getDescription() {
      if (this.location == "") {
        this.urlS = '';
        document.title =
          this.detail.cityid +
          this.detail.jobName +
          "-" +
          this.detail.jobName +
          "残疾人招聘详情-" +
          this.detail.companyInfo.companyName +
          "-兰州市残疾人就业创业网络服务平台";
        this.metaData.push(
          {
            name: "keywords",
            content:
              this.detail.jobName +
              this.detail.companyInfo.companyName +
              this.detail.cityid +
              "残疾人招聘,兰州市残疾人就业创业网络服务平台",
          },
          {
            name: "description",
            content:
              "兰州市残疾人就业创业网络服务平台,为残疾人提供" +
              this.detail.jobName +
              "的求职机会,以及" +
              this.detail.companyInfo.companyName +
              "的最新残疾人招聘信息,兰州市残疾人就业创业网络服务平台是中国残联官方的残疾人找工作的网站。",
          }
        );
      } else if (this.location == "陕西省") {
        this.urlS = '/shanxi';
        document.title =
          this.detail.cityid +
          this.detail.jobName +
          "-" +
          this.detail.jobName +
          "残疾人招聘详情-" +
          this.detail.companyInfo.companyName +
          "-陕西省残疾人就业创业网络服务平台";
        this.metaData.push(
          {
            name: "keywords",
            content:
              this.detail.jobName +
              this.detail.companyInfo.companyName +
              this.detail.cityid +
              "残疾人招聘,陕西省残疾人就业创业网络服务平台",
          },
          {
            name: "description",
            content:
              "陕西省残疾人就业创业网络服务平台,为残疾人提供" +
              this.detail.jobName +
              "的求职机会,以及" +
              this.detail.companyInfo.companyName +
              "的最新残疾人招聘信息,陕西省残疾人就业创业网络服务平台是陕西省残联官方的残疾人找工作的网站。",
          }
        );
      } else if (this.location == "甘肃省") {
        document.title =
          this.detail.cityid +
          this.detail.jobName +
          "-" +
          this.detail.jobName +
          "残疾人招聘详情-" +
          this.detail.companyInfo.companyName +
          "-兰州市残疾人就业创业网络服务平台";
        this.metaData.push(
          {
            name: "keywords",
            content:
              this.detail.jobName +
              this.detail.companyInfo.companyName +
              this.detail.cityid +
              "残疾人招聘,兰州市残疾人就业创业网络服务平台",
          },
          {
            name: "description",
            content:
              "兰州市残疾人就业创业网络服务平台,为残疾人提供" +
              this.detail.jobName +
              "的求职机会,以及" +
              this.detail.companyInfo.companyName +
              "的最新残疾人招聘信息,兰州市残疾人就业创业网络服务平台是兰州市残联官方的残疾人找工作的网站。",
          }
        );
      }
    },
  },
  created() {
    this.location = localStorage.getItem("locationSite");
    if (this.location == "") {
      this.$emit("goRouter", "/job/");
    } else if (this.location == "河南省") {
      this.$emit("goRouter", "/henan/job/");
    } else if (this.location == "湖南省") {
      this.$emit("goRouter", "/hunan/job/");
    } else if (this.location == "陕西省") {
      this.$emit("goRouter", "/job/");
    }
    this.info();
    //判断是否移动端
    this.$mobile(
      "https://lzmz.cdpee.org.cn/#/pages/index/jobDetail?id=" +
        this.$route.query.id
    );
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/job.less";

.el-button {
  /deep/ .el-loading-spinner i {
    color: #fff;
    font-size: 24px !important;
  }

  /deep/ .el-loading-spinner .el-loading-text {
    color: #fff;
  }
}

.ikone {
  color: #666;
  font-size: 14px;
}
</style>
